import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import ContextConsumer from "../services/context"
import ReleaseListContianer from "../components/releaseList/releaseListContainer"

import Layout from "../components/layout"
// import AllModules from '../components/allModules'
import { getToken } from "../utils/getToken"

import FeaturedRelease from "../components/featuredRelease"

export const query = graphql`
  {
    allModules(filter: {view: {slug: {eq: "discography-website"}}}) {
      edges {
        node {
          type
          title
          id
          itemId
        }
      }
    }
  }
`

class Discography extends Component {
  _isMounted = false
  state = {
    loading: true,
    error: false,
    contentItems: [],
    featuredContentItem: [{}],
    releases: [],
    releasesFetched: false,
    authTokenFetched: false,
    fetchedContentItems: false,
  }

  componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      this.fetchContentItems()
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let featuredRelease, backgroundImage
  
    let releaseList = <ReleaseListContianer 
      releases={this.state.releases}
      title="Discography"
      readyToLoad={false}
    />

    if ( this.state.releasesFetched ) {
      const featuredContentItem = this.state.featuredContentItem[0]
      const featuredBgImgUrl = featuredContentItem.imageUrl
      const backgroundImageCx ="fixed opacity-25 bgBlur"
      const backgroundImageStyle = {
        backgroundImage: "url(" + featuredBgImgUrl +")",
        backgroundSize: "cover",
        width: '120%',
        height: '120%',
        top: '-20%',
        left: '-20%',
        zIndex: -10,
      }
      backgroundImage = <div className={backgroundImageCx} style={backgroundImageStyle}></div>
      
    } else {
      featuredRelease = ""
      backgroundImage = ""
      // Add loaing state here
    }


    if ( this.state.authTokenFetched && this.state.releasesFetched ) {
      releaseList = <ReleaseListContianer 
        releases={this.state.releases}
        title="Discography"
        readyToLoad={this.state.releasesFetched}
      />
    }
    

    return (
      <>
        {backgroundImage}
        <FeaturedRelease 
          release={this.state.featuredContentItem[0]}
          dataFetched={this.state.fetchedContentItems} 
        />
        {releaseList}
      </>

    )

  }

  fetchRemainingReleasePages (pages) {
    for (let i = 2; i <= pages; i++) {
      getToken().then(options => {
        axios.get('https://api.ochre.io/v1/music/releases?page=' + i, options).then(response => {
          const releases = response.data.results  
          const newReleases = this.state.releases.concat(releases)
          this.setState({ releases: newReleases })
          if (i === pages) {
            this.setState({releasesFetched: true})
          }
        })
        .catch(error => {
          this.setState({ loading: false, error })
        })
      })
    }
  }

 
  fetchContentItems = async() => {
    getToken().then(options => {
      
      const allModules = this.props.data.allModules

      this.setState({
        authTokenFetched: true,
      })
      
      axios.get('https://api.ochre.io/v1/music/releases?', options).then(response => {
        const pages = response.data.summary.totalPages
        this.setState({
          discographyPages: pages,
          releases: response.data.results
        })
        console.log('fetched Releases ------', response)
        this.fetchRemainingReleasePages(pages)
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
      
      allModules.edges.map( module => {
        const moduleId = module.node.itemId  
        if ( module.node.title == "Featured" ) {
          axios 
          .get('https://api.ochre.io/v1/cms/modules/' + moduleId + '/content-items?image_size=l', options)
          .then(response => {
            this.setState({
              featuredContentItem: response.data.results,
              fetchedContentItems: true,
            })
          })
          .catch(error => {
            console.log('EEOR', error)
            this.setState({ loading: false, error })
          })
  
        }
      })

    }) 
  }


}
Discography.contextType = ContextConsumer;
export default Discography
